import React from "react";

const SideNav = () => {
    return (
        <div className="SideNav">
            <button id="Home">
                <img alt="" src={require("../assets/home.png")} />
            </button>
            <button id="Card">
                <img alt="" src={require("../assets/card.png")} />
            </button>
            <button id="Location">
                <img alt="" src={require("../assets/location.png")} />
            </button>
            <button id="Setting">
                <img alt="" src={require("../assets/setting.png")} />
            </button>
        </div>
    );
};

export default SideNav;
