import "./App.css";
import { useState } from "react";

import TopNav from "./components/TopNav.jsx";
import SideNav from "./components/SideNav.jsx";
import SmallNavBar from "./components/SmallNav.jsx";
import CategoriesItem from "./components/CategoriesItem.jsx";

function Home() {
    return (
        <>
            <div id="CategoriesContainer">
                <p id="Title">Popular categories</p>
                <div id="Categories">
                    <CategoriesItem name={"Phone"} image={require("./assets/phone.png")} />
                    <CategoriesItem name={"Tablet"} image={require("./assets/tablet.png")} />
                    <CategoriesItem name={"Laptop"} image={require("./assets/laptop.png")} />
                    <CategoriesItem name={"PC &  Screen"} image={require("./assets/pc.png")} />
                    <CategoriesItem name={"Smart watch"} image={require("./assets/watch.png")} />
                </div>
            </div>
            <div id="BestSelling">
                <p id="Title">Best selling item "để mai làm nay mệt lắm r -.-"</p>
            </div>
        </>
    );
}

function App() {
    const [SmallNav, setSmallNav] = useState(false);

    return (
        <div className="App">
            <TopNav SmallNav={SmallNav} setSmallNav={setSmallNav} />
            <SideNav />
            <div className="BodyContainer">
                <Home />
            </div>
            {SmallNav === true ? <SmallNavBar /> : <></>}
        </div>
    );
}

export default App;
