import React, { useState, useEffect } from "react";

const TopNav = ({ SmallNav, setSmallNav }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        // Cleanup the event listener
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (screenWidth >= 850) {
            setSmallNav(false);
        }
    }, [screenWidth, setSmallNav]);

    return (
        <div className="TopNav">
            <div id="LogoContainer">
                <p id="Logo">scartech</p>
            </div>
            <div id="SearchBarContainer">
                <div id="SearchLogo">
                    <img src={require("../assets/search.png")} alt="" />
                </div>
                <input id="SearchInput" placeholder="What are you looking for ?" />
            </div>
            <div id="FunctionContainer">
                <button id="Order">
                    <img src={require("../assets/order.png")} alt="" />
                </button>
                <button id="Love">
                    <img src={require("../assets/love.png")} alt="" />
                </button>
                <button id="Cart">
                    <img src={require("../assets/cart.png")} alt="" />
                </button>
                {screenWidth <= 850 ? (
                    <button
                        id="Menu"
                        onClick={() => {
                            if (SmallNav === false) {
                                setSmallNav(true);
                            } else {
                                setSmallNav(false);
                            }
                        }}
                    >
                        <img src={require("../assets/menu.png")} alt="" />
                    </button>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default TopNav;
