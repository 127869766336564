import React from "react";

const CategoriesItem = ({ name, image }) => {
    return (
        <div id="CategoriesItem">
            <div id="CategoriesImage">
                <img alt="" src={image} />
            </div>
            <div id="CategoriesItemTitle">
                <p>{name}</p>
            </div>
        </div>
    );
};

export default CategoriesItem;
